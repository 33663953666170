const links = [
  {
    label: 'Github',
    url: 'https://github.com/luizcorreia'
  },
  {
    label: 'Twitter',
    url: 'https://twitter.com/luiz__correia'
  },
  {
    label: 'Youtube',
    url: 'https://www.youtube.com/channel/UCyIloHqHvd2wBfSLpl-wCIw'
  },
  {
    label: 'Instagram',
    url: 'https://www.instagram.com/luiz__correia/'
  }
]

export default links
