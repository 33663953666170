import { Github } from 'styled-icons/boxicons-logos/Github'
import { Twitter } from 'styled-icons/boxicons-logos/Twitter'
import { Youtube } from 'styled-icons/boxicons-logos/Youtube'
import { Unsplash } from 'styled-icons/boxicons-logos/Unsplash'
import { Instagram } from 'styled-icons/boxicons-logos/Instagram'

const Icons = {
  Github: Github,
  Twitter: Twitter,
  Youtube: Youtube,
  Unsplash: Unsplash,
  Instagram: Instagram
}

export default Icons
